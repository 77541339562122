.flipVertically {
  transform: scaleY(-1);
}

.accordionSection {
  margin-bottom: 20px;
}

.accordionHeader {
  font-weight: bold;
  margin-bottom: 10px;
}

.customAccordion .card-header {
  border-bottom: 1px solid black;
  background-color: #fcdbcb;
}