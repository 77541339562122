.pricingContainer {
  text-align: center;
  padding: 40px;
}

.pricingSummary {
  margin-bottom: 40px;

  .pricingSummarySentence {
    margin-bottom: 10px;
  }
}

.pricingLearnMore {
  margin-bottom: 40px;
}