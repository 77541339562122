.HomeHeader {
  height: 6em;

  .logoWrapper {
    position: absolute;
    height: 100%;
    padding-left: 2em;
    //border: solid green 1px;

    .logoInnerWrapper {
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  .reportButton {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  .mobile {
    justify-content: left;
    padding-left: 2em;
  }

  .loginButton {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    padding-right: 2em;
  }
}
