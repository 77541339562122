.finePrint {
  display: flex;
  justify-content: flex-end;
  margin: 0 1em;

  p {
    font-size: 9px;
    margin-bottom: 0.5em;
  }
}
