.statementsWrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  justify-items: center;

  .statement {
    text-align: center;
  }

  .buttonsRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 2em;

    :first-child {
      margin-right: 1em;
    }

  }
}
